import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'js-cookie';

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: '',
    prepareHeaders: headers => {
      headers.set('Authorization', `Bearer ${Cookies.get('access_token')}`);

      return headers;
    },
  }),
  endpoints: builder => ({
    whoami: builder.query({
      query: () => '/api/whoami/',
    }),
  }),
});

export const { useWhoamiQuery } = apiSlice;
