import React, { useState, useEffect } from 'react';
import {
  Input,
  FormControl,
  FormLabel,
  Flex,
  Button,
  useToast,
} from '@chakra-ui/react';
import { useWhoamiQuery } from '../slices/apiSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateMember } from '../slices/memberSlice';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(true);
  const toast = useToast();
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let location = useLocation();

  const {
    data: member,
    isLoading,
    isSuccess,
    error,
  } = useWhoamiQuery({
    skip: loading,
  });

  useEffect(() => {
    if (isSuccess && member.user.email !== undefined) {
      const from = location.state?.from ? location.state?.from : '/resumes';

      dispatch(
        updateMember({
          email: member.user.email,
          permission: member.user.permission_group,
        })
      );
      navigate(from);
    }
  }, [isSuccess, isLoading]);

  const handleInput = (v, callback) => {
    const value = v.replace(/\s+/g, '');
    callback(value);
  };

  function authenticateUser() {
    fetch('/api/login/', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: email,
        password: password,
      }),
    }).then(res => {
      if (res.ok) setLoading(false);
      else {
        const status = res.status;
        res.json().then(res => {
          toast({
            title: status,
            description: res.detail,
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
          setEmail('');
          setPassword('');
        });
      }
    });
  }

  return isLoading ? null : (
    <Flex h="90vh" align="center" justify="center">
      <FormControl width="max-content">
        <FormLabel htmlFor="email">
          <span style={{ color: '#c5272f' }}>E</span>-mail adresa
        </FormLabel>
        <Input
          marginY={2}
          placeholder="e-mail"
          id="email"
          type="email"
          value={email}
          onChange={e => handleInput(e.target.value, setEmail)}
        />
        <FormLabel htmlFor="password">Lozinka</FormLabel>
        <Input
          marginY={2}
          placeholder="password"
          id="password"
          type="password"
          value={password}
          onChange={e => handleInput(e.target.value, setPassword)}
        />
        <Button marginY={4} onClick={authenticateUser}>
          Prijavi se
        </Button>
      </FormControl>
    </Flex>
  );
}
