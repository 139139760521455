import Login from "../components/Login"
import Resumes from "../components/Resumes"
import Admin from "../components/Admin"
import RequestReset from "../components/RequestReset"
import Reset from "../components/Reset"

const routes = [
    {
        path: "/",
        privateRoute: false,
        element: <Login />
    },
    {
        path: "/resumes",
        privateRoute: true,
        element: <Resumes />
    },
    {
        path: "/admin",
        privateRoute: true,
        element: <Admin />
    },
    {
        path: "/request_reset",
        privateRoute: false,
        element: <RequestReset />
    },
    {
        path: "/reset/:id",
        privateRoute: false,
        element: <Reset />
    },
]

export default routes