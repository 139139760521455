import React, { useState, useMemo, useEffect } from 'react';
import {
  Text,
  Input,
  Flex,
  Button,
  Box,
  Tooltip,
  Tag,
  useToast,
  Checkbox,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { useTable, useBlockLayout } from 'react-table';
import useDebounce from '../hooks/useDebounce';
import { FixedSizeList } from 'react-window';
import { FiHelpCircle } from 'react-icons/fi';
import Cookies from 'js-cookie';

export default function Resumes() {
  const [search, setSearch] = useState('');
  const [cfg, setCfg] = useState([]);
  const [resumes, setResumes] = useState([]);
  const [tagString, setTagString] = useState([]);
  const [uniString, setUniString] = useState('');
  const debounceSearch = useDebounce(search, 500);

  const [selected, setSelected] = useState([]);
  const [unis, setUnis] = useState([]);

  const access_token = Cookies.get('access_token');

  const [selectedCVs, setSelectedCVs] = useState([]);

  const toast = useToast();

  const fetchPlus = (url, options = {}) =>
    fetch(url, {
      ...options,
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
      }),
    }).then(res => {
      const status = res.status;
      const statusText = res.statusText;
      if (res.ok) {
        return res.json();
      } else if (status === 422) {
        console.log('Unprocessable Entity');
        fetch('/api/whoami/', {
          headers: new Headers({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${access_token}`,
          }),
        }).then(_ =>
          fetchPlus(
            `/api/resumes/?tags=${tagString}&search=${debounceSearch}&universities=${uniString}`
          )
        );
      } else {
        toast({
          title: `${status} ${statusText}`,
          description: res.detail,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    });

  useEffect(() => {
    fetchPlus(
      `/api/resumes/?tags=${tagString}&search=${debounceSearch}&universities=${uniString}`
    ).then(res => setResumes(res?.data));
  }, [debounceSearch, tagString, uniString]);

  useEffect(() => {
    fetchPlus('/api/config/').then(res => setCfg(res?.data));
  }, []);

  useEffect(() => {
    const updatedTagString = selected.reduce((acc, tag) => {
      return (acc += tag.label + ';');
    }, '');
    setTagString(updatedTagString);
  }, [selected]);

  useEffect(() => {
    const updatedUniString = unis.reduce((acc, tag) => {
      return (acc += tag.label + ';');
    }, '');
    setUniString(updatedUniString);
  }, [unis]);

  console.log(resumes);

  const headersData = useMemo(
    () => [
      {
        Header: 'Životopisi',
        columns: [
          {
            Header: 'Ime',
            id: 'ime',
            accessor: 'first_name',
          },
          {
            Header: 'Prezime',
            id: 'prezime',
            accessor: 'last_name',
          },
          {
            Header: 'E-mail',
            id: 'email',
            accessor: 'email',
          },
          {
            Header: 'Tags',
            id: 'tags',
            accessor: 'tags',
          },
          {
            Header: 'Fakultet',
            id: 'fakultet',
            accessor: 'university',
          },
          {
            Header: 'Smjer',
            id: 'smjer',
            accessor: 'course',
          },
          {
            Header: 'Godina',
            id: 'godina',
            accessor: 'year',
          },
          {
            Header: 'Pogledaj CV',
            id: 'pogledajcv',
            accessor: 'cv_url',
            Cell: ({ cell: { value } }) => {
              return (
                <Button as="a" target="_blank" href={value} size="sm">
                  CV
                </Button>
              );
            },
          },
          {
            Header: 'Preuzmi',
            id: 'preuzmi',
            accessor: 'cv_url',
            Cell: ({ cell: { value } }) => {
              return (
                <Button as="a" target="_blank" href={value} size="sm">
                  Preuzmi
                </Button>
              );
            },
          },
          {
            Header: 'Odaberi CV',
            id: 'odabericv',
            accessor: 'cv_url',
            Cell: ({ cell: { value } }) => {
              const shouldBeChecked = selectedCVs.includes(value);
              return (
                <Checkbox
                  isChecked={shouldBeChecked}
                  transition="0.5s linear"
                  onChange={() => updateSelectedCVs(value)}
                  margin="auto"
                />
              );
            },
          },
        ],
      },
    ],
    [selectedCVs]
  );

  function updateSelectedCVs(cv_url) {
    if (selectedCVs?.includes(cv_url)) {
      let cvs = [...selectedCVs];
      cvs = cvs.filter(cv => cv !== cv_url);
      setSelectedCVs(cvs);
    } else {
      setSelectedCVs(prev => [...prev, cv_url]);
    }
  }

  function downloadSelected() {
    if (selectedCVs?.length === 0) {
      toast({
        title: 'Error',
        description: "You haven't selected any resumes",
        status: 'error',
        duration: 3000,
        isClosable: true,
      });

      return;
    }

    fetch('/api/resumes/get_selected/', {
      method: 'POST',
      headers: {
        Accept: 'application/octet-stream',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        urls: selectedCVs,
      }),
    })
      .then(res => res.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'zivotopisi.zip');

        document.body.appendChild(link);
        link.click();

        link.parentNode.removeChild(link);
      });
  }

  const table = useTable(
    { columns: headersData, data: resumes },
    useBlockLayout
  );
  const {
    getTableProps,
    getTableBodyProps,
    totalColumnsWidth,
    headerGroups,
    rows,
    prepareRow,
  } = table;

  const CustomTag = React.forwardRef(({ children, ...rest }, ref) => (
    <Tag ref={ref} {...rest}>
      {children}
    </Tag>
  ));

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);
      return (
        <Box
          as="tr"
          {...row.getRowProps({
            style,
          })}
        >
          {row?.cells?.map(cell => {
            const { value: url = null } = row.cells.find(
              cell => cell.column.id === 'pogledajcv'
            );
            return (
              <Box
                p={2}
                minWidth="100px"
                maxWidth="250px"
                as="td"
                {...cell.getCellProps()}
                textOverflow="ellipsis"
                overflow="hidden"
                whiteSpace="nowrap"
                textAlign="center"
                bg={selectedCVs.includes(url) ? '#99ff99' : ''}
                _dark={{
                  bg: selectedCVs.includes(url) ? '#014421' : '',
                }}
              >
                <Tooltip label={cell.value}>
                  <CustomTag
                    bg="none"
                    as="span"
                    height="35px"
                    display="grid"
                    maxWidth="250px"
                  >
                    {cell.render('Cell')}
                  </CustomTag>
                </Tooltip>
              </Box>
            );
          })}
        </Box>
      );
    },
    [prepareRow, rows]
  );

  return resumes !== 0 && cfg?.length !== 0 ? (
    <Flex flexDir="column">
      <Flex align="flex-end" m="4" wrap="wrap">
        <Flex flexDir="column" margin="2">
          <Text my="2">Pretraži životopise:</Text>
          <Input
            value={search}
            onChange={e => setSearch(e.target.value)}
            type="text"
            size="sm"
            width="250px"
            borderRadius="md"
            placeholder="Type here..."
          />
        </Flex>
        <Flex margin="2" flexDir="column" width="450px">
          <Text my="2">Filtriraj po tagu:</Text>
          <Select
            closeMenuOnSelect={false}
            isMulti
            size="sm"
            borderRadius="md"
            hideSelectedOptions={false}
            placeholder="Odaberi tag..."
            onChange={e => {
              setSelected(e);
            }}
            options={cfg[0].tags}
          />
        </Flex>
        <Flex margin="2" flexDir="column" width="450px">
          <Text my="2">Filtriraj po fakultetu:</Text>
          <Select
            closeMenuOnSelect={false}
            isMulti
            size="sm"
            borderRadius="md"
            overflow="hidden"
            hideSelectedOptions={false}
            placeholder="Odaberi fakultet..."
            onChange={e => {
              setUnis(e);
            }}
            options={cfg[1].universities}
          />
        </Flex>
        <Flex margin="2" alignItems="center">
          <Button size="sm" onClick={downloadSelected}>
            Preuzmi odabrane
          </Button>
        </Flex>
        {/* <Flex margin="2" alignItems="center">
          <Button size="sm" onClick={downloadAll}>
            Preuzmi sve
          </Button>
        </Flex> */}
      </Flex>
      <Flex alignItems="center" justifyContent="center">
        <Box
          height="15px"
          width="15px"
          bg="#99ff99"
          borderRadius="100%"
          margin="2px"
          borderColor="black"
          borderWidth="1px"
        />
        <Text>/</Text>
        <Box
          height="15px"
          width="15px"
          bg="#014421"
          borderRadius="100%"
          margin="2px"
          borderColor="black"
          borderWidth="1px"
        />
        <Text marginLeft="2px">- Odabrani životopisi za preuzimanje</Text>
        <Tooltip
          label="Kliknite na 'Odaberi CV' u tablici koji želite preuzeti"
          textAlign="center"
        >
          <CustomTag
            bg="none"
            mx="1"
            _hover={{
              cursor: 'pointer',
            }}
          >
            <FiHelpCircle size={20} padding="2px" />
          </CustomTag>
        </Tooltip>
      </Flex>
      <Flex alignItems="center" flexDir="column" overflowX="scroll">
        <Box as="table" {...getTableProps()} boxShadow="md">
          <Box as="thead">
            {headerGroups.map(headerGroup => {
              return (
                <Box as="tr" {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => {
                    return (
                      <Box
                        p={2}
                        minWidth="100px"
                        fontSize="sm"
                        as="th"
                        borderBottomWidth="1px"
                        {...column.getHeaderProps()}
                      >
                        {column.render('Header')}
                      </Box>
                    );
                  })}
                </Box>
              );
            })}
          </Box>
          <Box as="tbody" {...getTableBodyProps()}>
            <FixedSizeList
              height={800}
              itemCount={rows?.length}
              itemSize={50}
              width={totalColumnsWidth}
            >
              {RenderRow}
            </FixedSizeList>
          </Box>
        </Box>
      </Flex>
    </Flex>
  ) : null;
}
