import React, { useState } from 'react';
import { Flex, Text, Box, Stack, useMediaQuery } from '@chakra-ui/react';
import { ColorModeSwitcher } from '../ColorModeSwitcher';
import { BsNewspaper } from 'react-icons/bs';
import { CgMenuCake, CgCloseR } from 'react-icons/cg';
import { FiLogOut } from 'react-icons/fi';
import { AiOutlineBug } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

export default function Navigation() {
  const [isOpen, setIsOpen] = useState(false);
  const [isSmallerThan770] = useMediaQuery('(max-width: 770px)');
  const navigate = useNavigate();

  const logout = () =>
    fetch('/api/logout/', {
      headers: {
        Authorization: `Bearer ${Cookies.get('access_token')}`,
      },
    }).then(_ => navigate('/'));

  return (
    <Flex
      as="nav"
      borderBottom="1px solid grey"
      align="center"
      wrap="wrap"
      transition="0.3s"
    >
      <Flex margin="0px 0px 0px 30px">
        <BsNewspaper size={24} />
        <Text>
          <span
            style={{
              color: '#c5272f',
              margin: '0px 0px 0px 10px',
              fontWeight: 'bold',
            }}
          >
            e
          </span>
          STUDENT CV baza
        </Text>
      </Flex>
      <Flex
        marginLeft="auto"
        align="center"
        order={isSmallerThan770 ? null : '3'}
      >
        <Box
          display={{ base: 'block', md: 'none' }}
          onClick={() => setIsOpen(!isOpen)}
        >
          {isOpen ? (
            <Box
              borderRadius="md"
              py={2}
              px={2}
              _hover={{
                cursor: 'pointer',
                bg: 'rgba(255, 255, 255, 0.08)',
              }}
            >
              <CgCloseR size={24} />
            </Box>
          ) : (
            <Box
              borderRadius="md"
              py={2}
              px={2}
              _hover={{
                cursor: 'pointer',
                bg: 'rgba(255, 255, 255, 0.08)',
              }}
            >
              <CgMenuCake size={24} />
            </Box>
          )}
        </Box>
        <ColorModeSwitcher />
        <Box
          borderRadius="md"
          p={2}
          mr={1}
          _hover={{
            cursor: 'pointer',
            bg: '#EDF2F7',
          }}
          _dark={{
            _hover: {
              cursor: 'pointer',
              bg: 'rgba(255, 255, 255, 0.08)',
            },
          }}
        >
          <AiOutlineBug size={23} />
        </Box>
        <Box
          borderRadius="md"
          p={2}
          _hover={{
            cursor: 'pointer',
            bg: '#EDF2F7',
          }}
          _dark={{
            _hover: {
              cursor: 'pointer',
              bg: 'rgba(255, 255, 255, 0.08)',
            },
          }}
        >
          <FiLogOut size={22} onClick={logout} />
        </Box>
      </Flex>
      <Box
        margin="0px 20px 0px 20px"
        display={{ base: isOpen ? 'block' : 'none', md: 'block' }}
        flexBasis={{ base: '100%', md: 'auto' }}
        _hover={{
          cursor: 'pointer',
          bg: 'rgba(255, 255, 255, 0.08)',
        }}
      >
        <Stack
          spacing={8}
          align="center"
          justify={['center', 'space-between', 'flex-end', 'flex-end']}
          direction={['column', 'row', 'row', 'row']}
          pt={[4, 4, 0, 0]}
        >
          <Text padding="2" onClick={() => navigate('/')}>
            Login
          </Text>
        </Stack>
      </Box>
    </Flex>
  );
}
