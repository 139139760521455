import React, { useState } from 'react';

import {
  Flex,
  Button,
  Input,
  FormControl,
  FormLabel,
  useToast,
} from '@chakra-ui/react';

export default function RequestReset() {
  const [email, setEmail] = useState('');
  const toast = useToast();

  const requestReset = () => {
    fetch('/api/request_reset/', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email.trim(),
      }),
    }).then(res => {
      const status = res.status;
      if (res.ok) {
        res.json().then(res => {
            toast({
              title: status,
              description: res.message,
              status: 'success',
              duration: 3000,
              isClosable: true,
            });
          });
      }
      else {
        res.json().then(res => {
          toast({
            title: status,
            description: res.detail,
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        });
      }
    });
  };

  return (
    <Flex height="90vh" align="center" justify="center">
      <FormControl width="max-content">
        <FormLabel htmlFor="email">
          Unesite <span style={{ color: '#c5272f' }}>e</span>
          -mail za resetiranje lozinke:
        </FormLabel>
        <Input
          placeholder="e-mail"
          id="email"
          type="email"
          my="1"
          value={email}
          onChange={v => setEmail(v.target.value)}
          size="sm"
        />
        <Button size="sm" my="2" onClick={requestReset}>
          Zatraži link
        </Button>
      </FormControl>
    </Flex>
  );
}
