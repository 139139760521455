import { extendTheme } from "@chakra-ui/react";
import '@fontsource/poppins';

const styles = extendTheme({
    global: {
        body: {
            margin: 0,
            padding: 0,
            fontFamily: "Poppins, sans-serif",
        }
    }
})

const shadows = {
    outline: "0 0 0 1px grey"
}

export { styles, shadows }