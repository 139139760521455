import { configureStore } from "@reduxjs/toolkit"
import { apiSlice } from '../slices/apiSlice'
import memberReducers from '../slices/memberSlice'

export default configureStore({
    reducer: {
        member: memberReducers,
        [apiSlice.reducerPath]: apiSlice.reducer
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(apiSlice.middleware)
})