import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { Provider, useSelector } from 'react-redux';
import theme from './configuration/theme';
import store from './configuration/store';
import Navigation from './components/Navigation';
import routes from './configuration/routes';
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate,
} from 'react-router-dom';

function RequireAuth({ children }) {
  let location = useLocation();
  const member = useSelector(state => state.member);

  if (!member.email) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}

function Application() {
  return (
    <BrowserRouter>
      <Routes>
        {routes.map(route => {
          return (
            <Route
              key={route.path}
              path={route.path}
              element={
                <>
                  <Navigation />
                  {route.privateRoute ? (
                    <RequireAuth>{route.element}</RequireAuth>
                  ) : (
                    route.element
                  )}
                </>
              }
            ></Route>
          );
        })}
      </Routes>
    </BrowserRouter>
  );
}

function App() {
  return (
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <Application />
      </ChakraProvider>
    </Provider>
  );
}

export default App;
