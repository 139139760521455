import React, { useState } from 'react';

import {
  Flex,
  Button,
  Input,
  FormControl,
  FormLabel,
  useToast,
} from '@chakra-ui/react';

import {
  useParams
} from "react-router-dom"

export default function Reset() {
  const [password, setPassword] = useState('');
  const toast = useToast();

  const { id } = useParams()

  const requestReset = () => {
    const url = `/api/reset/?password=${password}`
    fetch(url, {
      method: 'PATCH',
      headers: {
        "Authorization": `Bearer ${id}`
      },
    }).then(res => {
      const status = res.status;
      if (res.ok) {
        res.json().then(res => {
            toast({
              title: status,
              description: res.message,
              status: 'success',
              duration: 3000,
              isClosable: true,
            });
          });
      }
      else {
        res.json().then(res => {
          toast({
            title: status,
            description: res.detail,
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        });
      }
    });
    setPassword("")
  };

  return (
    <Flex height="90vh" align="center" justify="center">
      <FormControl width="max-content">
        <FormLabel htmlFor="email">
          Unesite novu lozinku:
        </FormLabel>
        <Input
          placeholder="password"
          id="password"
          type="password"
          my="1"
          value={password}
          onChange={v => setPassword(v.target.value)}
          size="sm"
        />
        <Button size="sm" my="2" onClick={requestReset}>
          Resetirajte lozinku
        </Button>
      </FormControl>
    </Flex>
  );
}
