import { extendTheme } from "@chakra-ui/react";

import { styles, shadows } from "./styles";

const overrides = {
    styles,
    shadows,
    components: {
        
    }
}

export default extendTheme(overrides)