import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    email: "",
    permissions: ""
}

const memberSlice = createSlice({
    name: "member",
    initialState,
    reducers: {
        updateMember(state, action) {
            const { email, permission } = action.payload
            state.email = email
            state.permissions = permission
        }
    }
})

export const { updateMember } = memberSlice.actions

export default memberSlice.reducer