import React, { useState } from 'react';
import { Flex, Text, Button, Box } from '@chakra-ui/react';

export default function Admin() {
  const [file, setFile] = useState({});

  const csvData = new FormData();

  const handleNewFile = e => {
    const { files: newFiles } = e.target;
    if (newFiles.length) {
      setFile(newFiles[0]);
    }
  };

  const handleUpload = () => {
    csvData.append('file', file);

    fetch('/api/parse_resumes/', {
      
      method: 'POST',
      body: csvData,
    });

    csvData.delete('file');
  };

  const handleUserUpload = () => {
    csvData.append('file', file);
    fetch('/api/bulk_add/', {
      method: 'POST',
      body: csvData,
    });
    csvData.delete('file');
  };

  return (
    <Flex>
      <Box>
        <Text>Upload zivotopise CSV</Text>
        <input type="file" title="" value="" onChange={handleNewFile} />
        <Button onClick={handleUpload}>Upload</Button>
        <Text>{file.name}</Text>
      </Box>
      <Box>
        <Text>Upload korisnik CSV</Text>
        <input type="file" title="" value="" onChange={handleNewFile} />
        <Button onClick={handleUserUpload}>Upload</Button>
      </Box>
    </Flex>
  );
}
